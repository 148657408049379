<template>
   <b-table
      outlined
      hover
      :items="items"
      :fields="fields"
      responsive
      class="m-0 text-center"
      select-mode="single"
      ref="dateTable"
      @row-selected="onRowSelected"
      :tbody-tr-class="rowClass"
      selected-variant="danger"
      selectable
   >
      <template v-slot:cell(start)="data" :data-sorter="data.item.start">
         <template v-if="data.item.start">
            {{ data.item.start }}
         </template>
      </template>
      <template v-slot:cell(end)="data" :data-sorter="data.item.end">
         <template v-if="data.item.end">
            {{ data.item.end }}
         </template>
      </template>
   </b-table>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
   props: ['planningDates', 'defaultSelectedDate'],
   data() {
      return {
         selectedDate: this.defaultSelectedDate,
         sort: 'start',
         fields: [
            { key: 'date', label: this.$t('ACTION.DATE.DATE') },
            { key: 'hour', label: this.$t('ACTION.DATE.HOUR') }
         ],
         items: []
      };
   },
   async mounted() {
      (await this.planningDates) &&
         //add index to each item
         this.planningDates.forEach((item, index) => {
            item.index = index;
         });
      //group this.planningDates by date pda_date and create hour_start with ase_h_deb and hour_end with ase_h_fin
      this.planningDates.forEach((date) => {
         if (date.ase_h_deb && date.ase_h_fin) {
            this.items.push({
               index: date.index,
               date: moment(date.pda_date).format('Do MMMM YYYY'),
               hour: `${date.ase_h_deb} - ${date.ase_h_fin}`
            });
         } else {
            this.items.push({
               index: date.index,
               date: moment(date.pda_date).format('Do MMMM YYYY'),
               hour: date.pda_demi === 1 ? 'Matin' : 'Après-midi'
            });
         }
      });
      this.$refs.dateTable.selectRow(this.planningDates);
   },
   methods: {
      onRowSelected(item) {
         this.selectedDate = item[0].index;
         this.$emit('dateSelected', this.selectedDate);
      },
      rowClass(item, type) {
         if (!item || type !== 'row') return;
         if (item.index === this.selectedDate) return 'table-danger';
      }
   },
   computed: {
      ...mapGetters({
         dates: 'listActionsDetails'
      })
   }
};
</script>
