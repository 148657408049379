<template>
   <div class="card card-custom gutter-b" :class="stretch && 'card-stretch'">
      <div class="card-header border-0 pt-5 pb-5 d-flex fleax-column">
         <h3 class="card-title w-100">
            <div class="w-100 d-flex justify-content-between align-items-center">
               <a v-if="src" :href="src" target="_blank" class="w-100 d-flex justify-content-between align-items-center">
                  <span class="card-label font-weight-bolder text-dark text-hover-primary" v-b-tooltip.hover="$t('GENERAL.OPEN_FILE')">{{
                     title
                  }}</span>
                  <i class="fas fa-external-link-alt p-0 text-dark ml-1" v-b-tooltip.hover="$t('GENERAL.OPEN_FILE')"></i>
               </a>
               <div v-else class="w-100 d-flex justify-content-between align-items-center">
                  <span class="card-label font-weight-bolder text-dark">{{ title }}</span>
                  <p style="font-size: 0.9rem" class="m-0"><b-badge variant="primary" pill>Indisponible</b-badge></p>
               </div>
            </div>
         </h3>
         <span v-if="subTitle" class="text-muted mt-3 font-weight-bold font-size-sm">{{ subTitle }}</span>
      </div>
      <div class="card-body pt-0" v-if="!src">
         <div class="alert alert-info m-0"><i class="fas fa-info-circle text-white"></i> {{ $t('GENERAL.NO_PDF_AVAILABLE') }}</div>
      </div>
   </div>
</template>

<script>
export default {
   props: ['src', 'title', 'subTitle', 'stretch']
};
</script>
